import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import { baseurl } from './BaseUrl';
import UpStreamSidemenu from './UpStreamSidemenu';

const get_erpcontent = baseurl + 'get_ErpContent';

class UpStream extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        };
    } componentDidMount() {


        axios.post(get_erpcontent)
            .then(Response => {
                // alert(JSON.stringify(Response.data.data[0].home));
                this.setState({
                    data: Response.data.data[0].erp
                });

            })
            .catch(err => {
                console.log(err);
            })



    }
    render() {
        return (
            <div>
                <div >
                    <img src="images/oilgas.jpg" className="responsiveImg"></img>
                </div>
                <div className="about-us-bg">u</div>
                <div className="about-us-bg1" style={{ marginTop: "-15px", marginLeft: "-30PX" }}> UPSTREAM <span style={{ color: "#1472b1" }}>SUITE</span> </div>
                <br></br>
                <br></br>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={3}>
                            <UpStreamSidemenu />
                        </Col>
                        <Col md={9}>
                            <br></br>
                            <div className="text-justify">
                                <p>Upstream Business suite consists of Production Database Management,
                                Production Revenue Accounting System, Gas Marketing Management System and L&G 360.
                                Suit maintain the well wise production database of hydrocarbons,
                                handled multiple Production Sharing contract & fiscal regime and Gas sales agreement (CNG & LNG).
                                Upstream Operation Excellence suit consist of ‘Right of way & land management system.
                                GIS based Facility Management, Disaster Management,
                                      Electronics work Permit system & knowledge Management System.</p>
                                <br></br>
                                <p>Integrated system facilitates to reduce the down time and to attend the O&M activities at shortest possible time</p>
                                <br></br>
                                <h5 style={{ color: "#1472b1" }}>Vedang Production Database Management System </h5>
                                <br></br>
                                <p>PDMS is a Production database that captures and manages the following information: </p>
                                <br></br>
                                <ul>
                                    <li> Production Database of Oil & Gas fields/blocks</li>
                                    <li> Database for CAPEX and OPEX of each block/field</li>
                                    <li> Online reporting of all PSC compliance</li>
                                    <li> Management of Production sharing contracts capturing PSC definitions-Cost Recovery, Profit Oil/Gas,
                                Division of Interest and Royalty, Partners Entitlement and Lifting etc.</li>
                                    <li>Tracking of Budget </li>
                                </ul>
                               
                                <br></br>
                                <Row className="justify-content-end">
                                    <Link to="/Production">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>
                                <br></br><br></br>

                              
                                <h5 style={{ color: "#1472b1" }}>Vedang Production Revenue Accounting System </h5>
                                <br></br>
                                <p>Vedang Production Revenue Accounting system,
                                handles multiple Production Sharing Contract’s on joint venture concept.
                                System calculates Royalty, Profit Petroleum,
                                Cost Petroleum and entitlement of Government and Partners.
                                System also keeps track of multiple investments & processes invoices for sale of Hydrocarbons
                                (Oil & Gas etc.). System has flexibility to generate various reports as
                                per the requirement of JV partners, Government and Regulator.

                                </p>

                                <br></br>
                                <Row className="justify-content-end">
                                    <Link to="/Revenue">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>
                                <br></br><br></br>

 
                                <h5 style={{ color: "#1472b1" }}>Vedang Gas Marketing Management System </h5>
                                <br></br>
                                <p>Gas Marketing Management System (GMMS) manages the Gas Sales Purchase Agreement (GSPA)
                                     and supports the 24X7 processes of Gas sales business like nomination, confirmation,
                                      scheduling, allocation and processing of invoices after considering the contractual obligations like monthly take or pay, 
                                      Liquid Damages and prior period adjustments (PPA) etc. GMMS act as a collaboration tool between the Marketer, 
                                    Transporter and customer for daily operation of Gas business with CRM features. of JV partners, Government and Regulator.

                                </p>

                                <br></br>
                                <Row className="justify-content-end">
                                    <Link to="/Gas">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>
                                <br></br><br></br>

                                
                                
                                <h5 style={{ color: "#1472b1" }}>Vedang L&G 360 </h5>
                                <br></br>
                                <p>Vedang L&G 360 is a comprehensive web based business solution for managing complete LNG business process right from planning 
                                    to shipping. It supports the processes like business planning, production planning, LNG delivery planning & scheduling, 
                                    shared LNG storage and loading terminal planning & scheduling, shared Non-LNG delivery & 
                                    storage and loading terminal planning & scheduling,
                                     Non-LNG cargo ownership split, shipping analysis etc.
                                </p>

                                <br></br>
                                <Row className="justify-content-end">
                                    <Link to="/LNG">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>
                                <br></br><br></br>

                            </div> <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

} export default UpStream



