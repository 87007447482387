import React, { Component } from 'react';
import { Container, Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Button, Row, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';

import { Link, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { baseurl } from './BaseUrl';

import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faEnvelope, faGraduationCap, faPhone, faFileAlt } from '@fortawesome/free-solid-svg-icons';


const jobcreate1 = baseurl + 'insertCarrerjobs';

class Job extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      Name: "",
      Email: "",

      PhoneNo: "",
      File: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);

  }
  handleNameChange = event => {
    this.setState({
      Name: event.target.value
    })
  }

  handleEmailChange = event => {
    this.setState({
      Email: event.target.value
    })
  }
  handlePhoneNoChange = event => {
    this.setState({
      PhoneNo: event.target.value
    })
  }

  handleFileChange = event => {
   //alert(event.target.files[0]);
    this.setState({File:event.target.files[0]});
  }


  handleSubmit = event => {
   // alert(` Hi ${this.state.PhoneNo} ,your message sent `)

  
    const formData = new FormData();
    formData.append('Name',this.state.Name);
    formData.append('Email',this.state.Email);
    formData.append('PhoneNumber',this.state.PhoneNo);
     formData.append('file',this.state.File);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    axios.post(jobcreate1,formData,config)
   
      .then(res => {
       // alert(JSON.stringify(res.status));
        if(res.status == 200) {
          alert("Thank you for showing interest in VedangSoft.");
          window.location.reload();
        }
        console.log(res);
        console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    event.preventDefault();
  }
  render() {
    return (
      <div style={{ backgroundColor: "#f0f0f0" }}>
        <Container>
          <br></br><br></br>
          <Row className="justify-content-center">

            <Col lg="6" md="8">

              <Card className=" shadow border-0 " style={{ backgroundColor: "white" }}>

                <CardBody className="px-lg-5 py-lg-5">
                  <div style={{ textAlign: "center", color: "gray" }}>
                    <img src={'./images/logo.png'} className="img-responsiveHead"></img>

                    <h3 className="text-muted text-center mt-2 mb-4"><u> Apply for Job </u></h3>
                    <br></br>
                    <Form onSubmit={this.handleSubmit}>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText style={{ backgroundColor: "white" }}>

                              <FontAwesomeIcon icon={faGraduationCap} aria-hidden="true" ></FontAwesomeIcon>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input style={{ boxShadow: "none" }} type="text" placeholder="Name*"
                            onChange={this.handleNameChange} required value={this.state.Name}
                          //  value={this.state.firstName} onChange={this.handleFirstName} 
                          // className={this.state.fnameError ? 'error' : null} 
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText style={{ backgroundColor: "white" }}>
                              {/* <i className="ni ni-email-83" /> */}
                              <FontAwesomeIcon icon={faEnvelope} aria-hidden="true" ></FontAwesomeIcon>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input style={{ boxShadow: "none" }} type="text"  placeholder="Email*"
                            value={this.state.Email}
                            onChange={this.handleEmailChange} required
                          // value={this.state.email} onChange={this.handleemail} className={this.state.lnameError ? 'error' : null}
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText style={{ backgroundColor: "white" }}>
                              {/* <i className="ni ni-email-83" /> */}
                              <FontAwesomeIcon icon={faPhone} style={{ transform: "rotate(90deg)" }} aria-hidden="true" ></FontAwesomeIcon>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input style={{ boxShadow: "none" }} type="number"  placeholder="Mobile Number*" maxlength="10" pattern="\d{10}" 
                          value={this.state.PhoneNo}
                          onChange={this.handlePhoneNoChange} required
                          // value={this.state.phone} onChange={this.handlePhone} className={this.state.phoneError ? 'error' : null} 
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText style={{ backgroundColor: "white" }}>
                              {/* <i className="ni ni-email-83" /> */}
                              <FontAwesomeIcon icon={faFileAlt} aria-hidden="true" ></FontAwesomeIcon>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input style={{ boxShadow: "none" }} type="file" 
                         
                          onChange={this.handleFileChange} required
                          // value={this.state.phone} onChange={this.handlePhone} className={this.state.phoneError ? 'error' : null} 
                          />
                        </InputGroup>
                      </FormGroup>


                      <FormGroup>

                        <Button type="submit" className="btnfrm" block >Submit</Button>

                      </FormGroup>
                      {/* <hr color='#045AC4' style={{ height: '1px' }}></hr><br></br> */}

                    </Form>
                  </div>




                </CardBody>




              </Card>
            </Col>
          </Row>
          <br></br><br></br>
        </Container>

      </div>

    )
  }

} export default Job