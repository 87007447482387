import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import { baseurl } from './BaseUrl';

const get_homecontent = baseurl + 'get_HomeContent';


class About extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        };
    }
    componentDidMount() {

        // axios.get(save_ip)
        // .then(Response => {
        //   alert(Response);

        // })
        // .catch(err => {
        // console.log(err);
        // })

        axios.post(get_homecontent)
            .then(Response => {
                // alert(JSON.stringify(Response.data.data[0].home));
                this.setState({
                    data: Response.data.data[0].home
                });

            })
            .catch(err => {
                console.log(err);
            })



    }
    render() {
        return (

            <div>
                <br></br>
                {/* <h3 style={{ textAlign: "center" }}>About Us</h3> */}

                <div className="about-us-bg">a</div>
                <div className="about-us-bg1" style={{ marginTop: "-7px" }}>ABOUT <span style={{ color: "#1472b1" }}>US</span> </div>
               
                <Container>
                    <Row className="justify-content-center">
                        <Col md={11}>

                            <article>

                                <div className="post__content" dangerouslySetInnerHTML={{ __html: this.state.data }}></div>
                            </article>
                            {/* <div className="text-justify">
                                Vedang Consultancy Service Private Limited (VCS) (Formerly known as RAMTeCH Energy Software Pvt. Ltd) is promoted by Oil & Gas leadership and practitioners team,
                                comprises of Ex Head of Oil & Gas Business and ex-CIOs of ONGC,
                                ONGC Videsh, Reliance, Texaco, Exxon Mobile, BP and Boeing USA and executed some of the largest Change Management ,
                                Organization Transformation ,Digital Oilfield and Systems implementation programs in Asia Pacific and other part of Globe.
                        <br></br><br></br>
                        Combined industry experience of these professional is more than 150 + Years and were known leaders in respective field.
                        Leadership & Core Solution Development team is also consists of ex-employees of Reliance, ONGC, BPCL, IOC, Exxon Mobil,
                        BP and Texaco Vedang provide cutting Edge Technology Solutions for complete business cycle with prime focus on Oil & Gas, Power, Utilities and E- Governance. Vedang also provide EPC (Engineering, Procurement & Construction) & Consulting Services for Process Automation/SCADA/DCS, Telecommunication, IT infrastructure, E- waste management and IT auditing. VCS owns more than 20 IPs for various industry verticals and are developed under the direct supervision of Industry domain experts.
                            </div>  */}

                            <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

} export default About


