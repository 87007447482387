import React, { Component } from 'react';
import { Container, Row, Col, InputGroupText, Input, FormGroup, Button, InputGroup, InputGroupAddon, Form, Card, CardTitle } from 'reactstrap';
import { baseurl } from './BaseUrl';
import { Link } from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHeart, faMapMarkerAlt, faPenAlt, faPhone, faStickyNote, faSignature, faAlignCenter } from '@fortawesome/free-solid-svg-icons';
import { } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import Marker from './Marker';
const Contactinfocreate1 = baseurl + 'Contactinfocreate';

const get_contactuscontent = baseurl + 'get_ContactusContent';

// const Marker = props => {
//     return <div></div>
// }

//const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends React.Component {
    static defaultProps = {
        center: {
             lat:28.53572339244153 ,
             lng:77.15016023977024
           
        },
        zoom: 15
    };
    constructor(props) {
        super(props);

        this.state = {
            visible: true,
            Name: "",
            Email: "",
            Subject: "",
            Message: "",
            data: '',
        };

        this.onDismiss = this.onDismiss.bind(this);

    }
    handleNameChange = event => {
        this.setState({
            Name: event.target.value
        })
    }

    handleEmailChange = event => {
        this.setState({
            Email: event.target.value
        })
    }
    handleSubjectChange = event => {
        this.setState({
            Subject: event.target.value
        })
    }

    handleMessageChange = event => {
        this.setState({
            Message: event.target.value
        })
    }

    onDismiss() {
        this.setState({ visible: false });
    }

    handleSubmit = event => {
        alert(` Hi ${this.state.Name} ,your message sent `)

        const user = {
            
            Name: this.state.Name,
            Email: this.state.Email,
            Subject: this.state.Subject,
            Message: this.state.Message,

        };
        axios.post(Contactinfocreate1, user)
            .then(res => {
                console.log(res);
                console.log(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        event.preventDefault();
    }


    componentDidMount() {

        axios.post(get_contactuscontent)
            .then(Response => {

                this.setState({
                    data: Response.data.data[0].contactus
                });

            })
            .catch(err => {
                console.log(err);
            })


    }
    render() {
        return (
            <div>
                <div >
                    <img src="images/contact.jpg" className="responsiveImg"></img>
                </div>
                <div className="about-us-bg">C</div>
                <div className="about-us-bg1" style={{ marginTop: "-25px" }}>CONTACT <span style={{ color: "#1472b1" }}>US</span> </div>

                {/* <div className="Background "> */}
                <div className="bg ">

                    <Container className="px-4">

                        <Row className="justify-content-center">

                            {/* <h2 style={{ color: "white" }}>CONTACT</h2> */}

                        </Row>
                        {/* <hr color="white"></hr> */}
                        <br></br>
                        <Row className="justify-content-center">

                            <h5 style={{ color: "white" }}>We'd <FontAwesomeIcon icon={faHeart} ></FontAwesomeIcon> to help!</h5>
                        </Row>

                        <Row className="justify-content-center">
                            <p style={{ color: "white", textAlign: "center" }}>
                                VedangSoft has been at the forefront of business automation through constant innovations, providing clients next-generation capabilities through smart governance technology and scalable infrastructure. To transform your business, get in touch with us.</p>
                        </Row>

                        <br></br><br></br>

                        <Row className="justify-content-center">
                            <Col md={5}>
                                <Form onSubmit={this.handleSubmit}>
                                    <FormGroup>

                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{ backgroundColor: "white" }}>

                                                    <FontAwesomeIcon className="hcolor" icon={faSignature} aria-hidden="true" ></FontAwesomeIcon>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input style={{ boxShadow: "none" }} type="text" placeholder="Enter your name*" value={this.state.Name}
                                                onChange={this.handleNameChange} required />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>

                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{ backgroundColor: "white" }}>

                                                    <FontAwesomeIcon className="hcolor" icon={faEnvelope} aria-hidden="true" ></FontAwesomeIcon>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input style={{ boxShadow: "none" }} type="email" placeholder="Enter email*" value={this.state.Email}
                                                onChange={this.handleEmailChange} required />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>

                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{ backgroundColor: "white" }}>

                                                    <FontAwesomeIcon className="hcolor" icon={faPenAlt} aria-hidden="true" ></FontAwesomeIcon>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input style={{ boxShadow: "none" }} type="text" placeholder="Subject*" value={this.state.Subject}
                                                onChange={this.handleSubjectChange} required />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>

                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText style={{ backgroundColor: "white" }}>

                                                    <FontAwesomeIcon className="hcolor" icon={faStickyNote} aria-hidden="true" ></FontAwesomeIcon>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input style={{ boxShadow: "none" }} type="textarea" placeholder="E-mail content*" value={this.state.Message}
                                                onChange={this.handleMessageChange} />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Button className="btn btn-info" block><b>Send</b></Button>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={6}>


                                <Row className="justify-content-center" style={{ paddingTop: "30px" }}>
                                    <Col sm={1} xs={1} md={2}>
                                        <FontAwesomeIcon style={{ color: "white", fontSize: "20px" }} icon={faMapMarkerAlt} ></FontAwesomeIcon> <br></br>
                                        <FontAwesomeIcon style={{ color: "white", fontSize: "20px", transform: "rotate(90deg)", marginTop: "45px" }} icon={faPhone} ></FontAwesomeIcon> <br></br>
                                        <FontAwesomeIcon style={{ color: "white", fontSize: "20px", marginTop: "18px" }} icon={faEnvelope} ></FontAwesomeIcon><br></br>

                                    </Col>



                                    <Col sm={11} xs={11} md={8}>

                                        <div style={{ textAlign:"start", color: "white" }}>
                                           
                                            <article>

                                                <div className="post__content" dangerouslySetInnerHTML={{ __html: this.state.data }}></div>
                                            </article>
                                            
                                        </div>

                                    </Col>

                                </Row>
                                <br></br><br></br>
                                <hr color="white"></hr>
                                <Row className="justify-content-center">
                                    <p style={{ color: "white" }}><img className="img-responsiveHead" src={'./images/logo.png'} ></img>&nbsp; www.vedangsoft.com</p>

                                </Row>
                            </Col>
                        </Row>


                    </Container>



                    <br></br>
                    <br></br>
                    <br></br>


                </div>
                <br></br>
                <br></br>

                {/* <CardTitle><b>Map</b></CardTitle> */}
                <Row>
                    <Col md={12}>
                        <Card style={{ padding: '10px' }}>
                            <div style={{ height: '600px', width: '100%', maxHeight: "auto", maxWidth: "100%" }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyBKdXoEy8iDv_7yvzRJkH2mW9LFieOIT4g' }}
                                    defaultCenter={this.props.center}
                                    defaultZoom={this.props.zoom}>
                                    <Marker 
                                   
                                    lat={28.53572339244153} 
                                    lng={77.15016023977024} 
                                    text="Vedangsoft"
                                    color="blue"
                                   />
                                 
                                </GoogleMapReact>
                            </div>
                        </Card>
                    </Col>
                </Row>
                {/* <Button>Go somewhere</Button> */}
                <br></br>
                <br></br>
                <br></br>
            </div>
        );
    }

} export default Contact


