import React, { Component } from 'react';
import { Button, Navbar, Nav, NavDropdown, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
// import {Row,Col} from 'reactstrap';

class Header extends React.Component {

    render() {


        return (
            <div>
                <Navbar collapseOnSelect expand="lg" bg="dark" className="fixed-top" variant="dark" style={{ zIndex: "9999" }}>
                    <Container >

                        <Navbar.Brand as={Link} to="/" >

                            <div className="logoDiv">
                                <img
                                    src={'./images/logo.png'} className="img-responsiveHead"></img>
                            </div>
                            {/* &nbsp;
                                <b className="text-gradient">Vedang Consultancy Service Pvt. </b> */}

                        </Navbar.Brand>
                        <Navbar.Brand as={Link} to="/" >

                            {/* <b className="text-gradient" >VedangSoft</b>E22485 */}
                            <b className="brandname">VedangSoft</b>
                        </Navbar.Brand>

                        {/* <Navbar.Brand as={Link} to="/" >
                            
                            {/* <div className="text-gradient" style={{ marginTop: '10px' }}> 
                                <b className="text-gradient">Vedang Software Pvt. Ltd.</b>
                           </div> 
                        </Navbar.Brand>
                        */}

                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Item>
                                    <Nav.Link eventKey="1" as={Link} to="/" >
                                        {/* <FontAwesomeIcon style={{color:"white"}} icon={faHome}  aria-hidden="true"></FontAwesomeIcon> */}
                                             Home
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item >
                                    <NavDropdown title="About" id="basic-nav-dropdown">
                                        <NavDropdown.Item eventKey="1" as={Link} to="/Company">Company</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="2" as={Link} to="/People">Our People</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav.Item>


                                <Nav.Item >
                                    <NavDropdown title="Solutions " id="basic-nav-dropdown">
                                        <div style={{ width: "660px", maxWidth: "100%", padding: "20px", maxHeight: "auto" }}>
                                            <Row>
                                                <Col md={5}>
                                                    <h6>Browse by Industries:</h6>
                                                    <hr></hr>
                                                    <NavDropdown.Item eventKey="1" as={Link} to="/EGovernance">E- Governance</NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="2" as={Link} to="/ERP">ERP</NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="3" as={Link} to="/GovernmentandPublicSector">Government and Public Sector</NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="4" as={Link} to="/OilGas"> Oil and Gas</NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="5" as={Link} to="/Water"> Water </NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="6" as={Link} to="/Health"> Health</NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="7" as={Link} to="/IT"> Information Technology</NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="8" as={Link} to="/Construction"> Construction</NavDropdown.Item>
                                                    <br></br>
                                                </Col>
                                                <Col md={1}></Col>
                                                <Col md={6}>
                                                    <h6>Browse by Business:</h6>
                                                    <hr></hr>
                                                    <NavDropdown.Item eventKey="9" as={Link} to="/HR"> Human Resource</NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="10" as={Link} to="/assetmanagement"> Asset Management</NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="11" as={Link} to="/ProcurementandMaterialsManagement"> Procurement and Materials Management</NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="12" as={Link} to="/CivilEngineering"> Civil Engineering</NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="13" as={Link} to="/Services"> Services </NavDropdown.Item>
                                                    <NavDropdown.Item eventKey="14" as={Link} to="/Consultancy"> Consultancy</NavDropdown.Item>
                                                </Col>
                                            </Row>
                                        </div>

                                    </NavDropdown>
                                </Nav.Item>

                                {/* <Nav.Item >
                                    <NavDropdown title="Our Customers " id="basic-nav-dropdown">
                                        <NavDropdown.Item eventKey="1" as={Link} to="/"> Company</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="2" as={Link} to="/pujas">Our People</NavDropdown.Item>
                                        <NavDropdown.Item eventKey="2" as={Link} to="/pujas">Our People</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav.Item> */}


                                <Nav.Item>
                                    <Nav.Link eventKey="1" as={Link} to="/Customers" >
                                    Our Customers 
                                    </Nav.Link>
                                </Nav.Item>
                                {/*  <Nav.Item>
                                    <Nav.Link eventKey="1" as={Link} to="/CSR" >
                                        CSR
                                    </Nav.Link>
                                </Nav.Item>*/}
                                {/* 
                                <Nav.Item>
                                    <Nav.Link eventKey="1" as={Link} to="/" >
                                        Latest News
                                    </Nav.Link>
                                </Nav.Item> */}


                                <Nav.Item>
                                    <Nav.Link eventKey="1" as={Link} to="/Career" >
                                        Careers
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="1" as={Link} to="/Contact" >
                                        Contact Us
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>



                        </Navbar.Collapse>


                    </Container>
                </Navbar>
            </div>

        );
    }

}
export default Header







