import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import { baseurl } from './BaseUrl';
import MidStreamSidemenu from './MidStreamSidemenu';

const get_erpcontent = baseurl + 'get_ErpContent';

class MidStream extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        };
    } componentDidMount() {


        axios.post(get_erpcontent)
            .then(Response => {
                // alert(JSON.stringify(Response.data.data[0].home));
                this.setState({
                    data: Response.data.data[0].erp
                });

            })
            .catch(err => {
                console.log(err);
            })



    }
    render() {
        return (
            <div>
                <div >
                    <img src="images/oilgas.jpg" className="responsiveImg"></img>
                </div>
                <div className="about-us-bg">m</div>
                <div className="about-us-bg1" style={{ marginTop: "-15px", marginLeft: "-20PX" }}> <span style={{ color: "#1472b1" }}>MIDSTREAM</span> </div>
                <br></br>
                <br></br>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={3}>
                            <MidStreamSidemenu />
                        </Col>
                        <Col md={9}>
                            <div className="text-justify">

                                <br></br>
                               <p>Midstream suite consists of E- Pipe book, GIS based Right of way & land management system, 
                                   Gas Pipeline Management System and L&G 360, GIS based Facility Management, 
                                   Disaster Management and Electronics work Permit system. The system allow user to 
                                   shift from traditional stand-alone maintenance practice to Integrated Asset Integrity maintenance process.</p>
                                <br></br><br></br>


                                <h5 style={{ color: "#1472b1" }}>Vedang ROU-LMS</h5>
                                <br></br>
                                <p>Vedang RoU-LMS covers complete business process for acquiring & managing the “Right of Way 
                                    “for Oil & Gas Pipelines or any Oil & Gas installations. System supports & the maintain the cadastral databases of landowners , 
                                    generation of government notices / notifications , objection from landowners , compensation calculation based on area ,
                                     payment details, award generation, termination details etc. System also maintain the databases of permissions/
                                      regulatory approvals for forest land,
                                     crossing like railway, highway, river etc. System is also available on GIS platform.

</p>

                                <br></br>
                                <Row className="justify-content-end">
                                    <Link to="/ROULMS">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>  <br></br><br></br>


                                <h5 style={{ color: "#1472b1" }}>Vedang E-Pipebook </h5>
                                <br></br>
                                <p>Vedang E-Pipe book captured & monitor the various construction activities of
                                     Oil & Gas Pipelines and facilitates to generate automatically ‘As Built “ .
                                      System also tracks the progress details of construction activities on GIS platform.
                                       System captures all the details like survey, clearing, grading, Right of way , 
                                       pipe stringing, pipe cutting ,bending details , trenching, blasting, welder & welding , 
                                       NDT & joint coating & repairing , lowering & bottom padding, back-filling and top padding, hydro-testing,
                                        restoration, TCP/PCP, 
                                    cathodic protection, OFC blowing/jointing, crossings details etc. System is also available on GIS platform.
</p>

                                <br></br>
                                <Row className="justify-content-end">
                                    <Link to="/EPipebook">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>  <br></br><br></br>


                                <h5 style={{ color: "#1472b1" }}>Vedang Pipeline Construction Monitoring System </h5>
                                <br></br>
                                <p>This application is designed to monitor the construction progress of pipeline on a real time basis. 
                                    Data that is fed in the E-Pipebook system on a daily basis is indexed using complex algorithm that leaves no scope for discrepancies.
                                     The solution is designed to provide real time information about pipeline progress during following three stages, project planning,
                                     project execution/construction, operation & maintenance. 
                                    Tracking of each progress activity is reported in form of data, graphical reports and geo-referenced maps.
</p>

                                <br></br>
                                <Row className="justify-content-end">
                                    <Link to="/Monitoring">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>  <br></br><br></br>


                                <h5 style={{ color: "#1472b1" }}>Vedang Gas Pipeline Management System</h5>
                                <br></br>
                                <p>Vedang Gas PipeLine Management System(GPMS),manages the Gas Transportation Agreement(GTA) 
                                    and supports 24X7 Gas transportation operations like nomination,confirmation,pipeline scheduling,allocation,
                                    pipeline inventory,imbalances etc. and processing of invoices after considering the contractual obligations
                                     like monthly take or pay,imbalances,liquidated damages and prior period adjustment(PPA) etc.
                                     The system act as a collaboration tool between the Gas producer,
                                    shippers and Gas Transporter for daily operation of Gas business.

                                </p>

                                <br></br>
                                <Row className="justify-content-end">
                                    <Link to="/Pipeline">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>  <br></br><br></br>


                                <h5 style={{ color: "#1472b1" }}>Vedang L&G 360</h5>
                                <br></br>
                                <p>An advanced web based solution for effectively managing a wide spectrum of processes, 
                                    services and transactions; ranging from planning to shipping. 
                                    The solution encapsulates the complete business process of L & G business 
                                    and has earned appreciation from some of the leading L & G companies in Middle East.



                                </p>

                                <br></br>
                                <Row className="justify-content-end">
                                    <Link to="/LNG">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>
                            </div> <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

} export default MidStream


