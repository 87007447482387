import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";



class SidemenuOne extends React.Component {
    
    render() {
        return (

            <div className="sidemenu">
                <ul>
               
                                <li> <Link to="/ROULMS" className="sidelink"> Vedang ROU-LMS     </Link> </li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li>  <Link to="/EPipebook" className="sidelink">Vedang E-Pipebook    </Link></li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li> <Link to="/GovernmentandPublicSector" className="sidelink">Vedang Pipeline &nbsp;&nbsp;Construction Monitoring &nbsp;&nbsp;System   </Link> </li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li> <Link to="/OilandGas" className="sidelink">Vedang Gas PipeLine &nbsp;&nbsp;Management System   </Link> </li>
                                <hr className="dotted"style={{marginTop:"5px"}} ></hr>
                                <li> <Link to="/Water" className="sidelink">Vedang L&G 360     </Link> </li>
                                <hr className="dotted"style={{marginTop:"5px"}} ></hr>
                                

                </ul>
                

               
            </div>
        );
    }

} export default SidemenuOne


