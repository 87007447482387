import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import { baseurl } from './BaseUrl';
import Sidemenunav from './Sidemenunav';

const get_erpcontent = baseurl + 'get_ErpContent';

class ERP extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        };
    } componentDidMount() {


        axios.post(get_erpcontent)
            .then(Response => {
                // alert(JSON.stringify(Response.data.data[0].home));
                this.setState({
                    data: Response.data.data[0].erp
                });

            })
            .catch(err => {
                console.log(err);
            })



    }
    render() {
        return (
            <div>
                <div >
                    <img src="images/ERP.jpg" className="responsiveImg"></img>
                </div>
                <div className="about-us-bg">E</div>
                <div className="about-us-bg1" style={{ marginTop: "-25px", marginLeft: "-30PX" }}> <span style={{ color: "#1472b1" }}>ERP</span> </div>
                
                <Container>
                    <Row className="justify-content-center">
                        <Col md={3}>
                            <Sidemenunav />
                        </Col>
                        <Col md={9}>
                            <div className="text-justify">

                                <br></br>
                                <h5 style={{ color: "#1472b1" }}>Human Resource Management System </h5>
                                <br></br>
                                <p><b>VedangHRMS360™</b> is a web based Human Resource Management System for capturing the
                                    entire process of HR from induction to retirement. The system covers all function of the Human Resource Department
                                     that deals with recruitment, employees' management, payroll, performance, training etc.
                                      System provides the integrated solution of HR & Payroll.</p>
                                <Row className="justify-content-end">
                                    <Link to="/HR">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>
                                <br></br><br></br>

                                <br></br>
                                <h5 style={{ color: "#1472b1" }}>E-Procurement & Asset Management System </h5>
                                <br></br>
                                <p>System provides complete solution for manging the Procurement, Inventory & Asset Management. 
                                    E-Procurement & Asset Management System consists of following four modules</p>
                                    <br></br>
                                    <Link to="/ProcurementandMaterialsManagement" >  <h5 className="innerlink">E-TENDER</h5>  </Link>
                                    <Link to="/ProcurementandMaterialsManagement">  <h5 className="innerlink">E-PROCUREMENT</h5>  </Link>
                                    <Link to="/assetmanagement">  <h5 className="innerlink">ASSET MANAGEMENT</h5>  </Link>
                                    <Link to="/WCM">  <h5 className="innerlink">WORKS CONTRACT MANAGEMENT</h5>  </Link>
                                <article>

                                    <div className="post__content" dangerouslySetInnerHTML={{ __html: this.state.data }}></div>
                                </article>


{/* 

                    Human Resource Management System
                    <br></br>  <br></br>
                    VedangHRMS360™ is a web based Human Resource Management System for capturing the entire process of HR from induction to retirement. The system covers all function of the Human Resource Department that deals with recruitment, employees' management, payroll, performance, training etc. System provides the integrated solution of HR & Payroll.

                    <br></br>  <br></br>
                    E-Procurement & Asset Management System
                    System provides complete solution for manging the Procurement, Inventory & Asset Management. E-Procurement & Asset Management System consists of following four modules
                    <br></br> <br></br>
                    E-TENDER
                    <br></br><br></br>
                    E-PROCUREMENT
                    <br></br><br></br>
                    ASSET MANAGEMENT
                    <br></br><br></br>
                    WORKS CONTRACT MANAGEMENT
                    <br></br><br></br>

                    Finance & Accounting System
                    <br></br><br></br>
                    Vedang "F&A" is an innovative financial accounting software and capable of handling complex business requirements . Software is suitable for Government & Private Sector companies of all sizes. System is user friendly, easy to navigate and users can also learn basic of Finance & Accounting quickly. System is capable of generating real time P&L's with simplified bookkeeping processes thus save more time for data entry & analysis. Users can create customize accounting reports & role based dynamic dash board apart from regulatory compliance reports without any support from IT developers. Due to dynamic architecture of process, system can be implemented in few weeks irrespective of size of Organization */}

                    </div> <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

} export default ERP


