import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";



class UpStreamSidemenu extends React.Component {
    
    render() {
        return (

            <div className="sidemenu">
                <ul>
               
                                <li> <Link to="/Production" className="sidelink">Vedang Production &nbsp;&nbsp;Database Management &nbsp;&nbsp;System    </Link> </li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li>  <Link to="/Revenue" className="sidelink">Vedang Revenue &nbsp;&nbsp;Accounting System    </Link></li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li> <Link to="/Gas" className="sidelink">Vedang Gas Marketing &nbsp;&nbsp;Management System  </Link> </li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li> <Link to="/LNG" className="sidelink">Vedang L & G 360    </Link> </li>
                                <hr className="dotted"style={{marginTop:"5px"}} ></hr>
                               
                              

                </ul>
                

               
            </div>
        );
    }

} export default UpStreamSidemenu


