import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { Link } from "react-router-dom";

import UpStreamSidemenu from './UpStreamSidemenu';

class OilGas extends React.Component {

    render() {
        return (
            <div>
                <div >
                    <img src="images/oilgas.jpg" className="responsiveImg"></img>
                </div>
                <div className="about-us-bg">g</div>
                <div className="about-us-bg1" style={{ marginTop: "-18px" }}>OIL &<span style={{ color: "#1472b1" }}>GAS</span> </div>
               
                <Container>
                    <Row className="justify-content-center">
                        <Col md={3}>
                            <UpStreamSidemenu />
                        </Col>
                        <Col md={9}>
                            <div className="text-justify">
                               

<br></br>
                                <Link to="/UpStream"> <h4 style={{color:"black"}} className="innerlink">UPSTREAM SUITE</h4></Link>

                                <br></br>
                                <Link to="/MidStream"> <h4 style={{color:"black"}} className="innerlink">MIDSTREAM SUITE</h4></Link>

                            </div> <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }

} export default OilGas


