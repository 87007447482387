import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import { baseurl } from './BaseUrl';
import Job from './Job';

const get_careercontent = baseurl + 'get_CareerContent';
const jobcreate1 = baseurl + 'jobcreate';

class Career extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        };
    } componentDidMount() {

        axios.post(get_careercontent)
            .then(Response => {
                // alert(JSON.stringify(Response.data.data[0].home));
                this.setState({
                    data: Response.data.data[0].career
                });

            })
            .catch(err => {
                console.log(err);
            })



    }
    render() {
        return (
            <div >
                <div >
                   {/*  <img src="https://i.postimg.cc/m2HQYKFg/career.jpg" className="responsiveImg"></img>*/}
                    <img src="/images/career1.png" className="responsiveImg"></img>
                </div>
                <br></br>
                <br></br>
                <div className="about-us-bg">C</div>
                <div className="about-us-bg1" style={{ marginTop: "-25px" }}> <span style={{ color: "#1472b1" }}>CAREERS</span> </div>
               
                <Container>

                    <p className="pfont text-justify">We are hiring but looking for future leaders not for just worker. If you have appetite for innovation and have ideas to convert you’re thought into solution then join <span className="text-gradient">VedangSoft</span> </p>

                   
                    <Row className="justify-content-center">
                        <Col md="10">

                            <div>

                                <article>

                                    <div className="post__content" dangerouslySetInnerHTML={{ __html: this.state.data }}></div>
                                   
                                </article>
                              
                            </div>
                            <div style={{ marginTop: "-85px" }}>
                          <p><b>Send your updated resume to HR at  - </b> <a  href = "mailto: Santosh.Sharma@wayme.net">santosh.sharma@vedang.net </a>
                          </p>  </div>
{/* 
                            <p className="pfont  text-justify"><h4 className="hcolor">IT Business: </h4>Qualification :BE/MCA/Diploma in IT/BCA Experience( 0-3 Years)</p>
                            <h5 > </h5>
                            <p className="pfont text-muted text-justify">• Intern/Training</p>
                            <p className="pfont text-muted text-justify">• Fresher/Trainee Software Developer</p>
                            <p className="pfont text-muted text-justify">• Senior Software</p>
                            <p className="pfont text-muted text-justify">• Developer (C# / .Net): : C#.net, VB.net, Asp.net with SQL server an full product life cycle</p>
                            <p className="pfont text-muted text-justify">• Android Developer: Android SDK, UI design, Core java, XML, SQLite database, APIs Coding tools in Eclipse and studio.</p>
                            <p className="pfont text-muted text-justify">• WEB/UI Designer: HTML, CSS and JavaScript.Able to develop Flash based Websites, Ad banner, Flash Banner, brochures, Logos, Graphics.</p>
                            <p className="pfont text-muted text-justify">• Network Engineers:Cisco CCNA certification Marketing Managers/ Executives</p>



                            <br></br>


                            <h4 className="hcolor" >Waste Management :</h4>
                            <br></br>
                            <p className="pfont  text-justify">• Marketing Managers & Executive</p>
                            <p className="pfont  text-justify"><b>Send your updated resume to HR Santosh.Sharma@wayme.net</b></p>
 */}
                      

                        </Col>
                    </Row>
                  
                </Container>
              
                <Job/>


                <br></br>
                <br></br>




            </div>
        )
    }

}
export default Career