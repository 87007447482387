import React, { Component } from 'react';
import { Container,Row,Col,Button } from 'reactstrap';
import { Link } from "react-router-dom";
import Sidemenunav from './Sidemenunav';
class Construction extends React.Component {

    render() {
        return (
            <div>
                <div >
                    <img src="images/construction.jpg" className="responsiveImg"></img>
                </div>
                <div className="about-us-bg">C</div>
                <div className="about-us-bg1" style={{marginTop:"-26px"}}> <span style={{ color: "#1472b1" }}>CONSTRUCTION</span> </div>
               
                <Container>
                    <Row className="justify-content-center">
                    <Col md={3}>
                            <Sidemenunav />
                        </Col>
                        <Col md={9}>
                            <div className="text-justify">
                            <br></br>
                                <h5 style={{ color: "#1472b1" }}>Vedang WOCMS</h5>
                                <p>(Work order Contract Management System) <br></br>
                                Suitable for Civil Construction, PWD, Irrigation, State Highway, Rural Engineering, Municipalities
“Vedang WOCMS “is suitable for all Civil Construction Departments in Government Sector.</p>
                                <Row className="justify-content-end">
                                    <Link to="/WOCMS">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>
                                <br></br><br></br> 
                               
                                <h5 style={{ color: "#1472b1" }}>  Vedang IntelliBuilding Approval System</h5>
                                <p>For Automation of Building Scrutiny & approval: Suitable for Municipalities and agencies responsible for approving construction of buildings</p>
                                <Row className="justify-content-end">
                                <Link to="/Intellibuilding"> <Button className="btn btn-danger">Read More</Button> </Link>
                                 </Row>
                                 <br></br><br></br> 

                                 <h5 style={{ color: "#1472b1" }}>Vedang E-Pipebook</h5>
                                <p>Vedang E-Pipe book captured & monitor the various construction activities of Oil & Gas Pipelines and facilitates to generate automatically ‘As Built “ . System also tracks the progress details of construction activities on GIS platform. System captures all the details like survey, clearing, grading, Right of way , pipe stringing, pipe cutting ,bending details , trenching, blasting, welder & welding , NDT & joint coating & repairing , lowering & bottom padding, back-filling and top padding, hydro-testing, restoration, TCP/PCP, cathodic protection, OFC blowing/jointing, crossings details etc. System is also available on GIS platform.</p>
                                <Row className="justify-content-end">
                                    <Link to="/EPipebook">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>
                                <br></br><br></br> 
                          
                                <h5 style={{ color: "#1472b1" }}> Vedang ROU-LMS</h5>
                                <p>Vedang RoU-LMS covers complete business process for acquiring & managing the “Right of Way “for Oil & Gas 
                                    Pipelines or any Oil & Gas installations. System supports & the maintain the cadastral databases of
                                     landowners , generation of government notices / notifications , objection from landowners , compensation 
                                     calculation based on area , payment details, award generation, termination details etc. System also 
                                     maintain the databases of permissions/ regulatory approvals for forest land, crossing like railway,
                                      highway, river etc. System is also available on GIS platform.</p>
                                <Row className="justify-content-end">
                                    <Link to="/ROULMS">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>
                                <br></br><br></br> 



                               </div> <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

} export default Construction


