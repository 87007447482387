import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import Sidemenu from './Sidemenu';
class CivilEngg extends React.Component {

    render() {
        return (
            <div>
                <div >
                    <img src="images/civilengg.jpg" className="responsiveImg"></img>
                </div>
                <div className="about-us-bg">c</div>
                <div className="about-us-bg1" style={{ marginTop: "-13px" }}>CIVIL  <span style={{ color: "#1472b1" }}>ENGINEERING</span> </div>
                
                <Container>
                    <Row className="justify-content-center">
                        <Col md={3}>
                            <Sidemenu />
                        </Col>
                        <Col md={9}>
                            <div className="text-justify">
<br></br>
                                <h5 style={{ color: "#1472b1" }}>Vedang WOCMS</h5>
                                (Work order Contract Management System) 
                              <p>  Suitable for Civil Construction, PWD, Irrigation, State Highway, Rural Engineering, Municipalities
“Vedang WOCMS “is suitable for all Civil Construction Departments in Government Sector.</p>
                                <Row className="justify-content-end">
                                    <Link to="/WOCMS">   <Button className="btn btn-danger">Read More</Button> </Link>
                                </Row>
                                <br></br><br></br>
                                <h5 style={{ color: "#1472b1" }}>  Vedang IntelliBuilding Approval System</h5>
                                <p>For Automation of Building Scrutiny & approval: Suitable for Municipalities and agencies responsible for approving construction of buildings</p>
                                <Row className="justify-content-end">
                                <Link to="/Intellibuilding"> <Button className="btn btn-danger">Read More</Button> </Link>
                                 </Row>

                            </div> <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

} export default CivilEngg


