import React, { Component } from 'react';
import { } from 'reactstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Link } from "react-router-dom";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
class Techslider extends React.Component {
   
    render() {
        return (
            <div>
               
              
<Carousel 
  swipeable={false}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={5000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>

  <div style={{marginLeft:"50px",marginRight:"30px"}} ><img className="technology-img" src="/images/aspnet.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="technology-img" src="/images/mvc.jpeg" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="technology-img" src="/images/core.png" alt="loading image"></img> </div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="technology-img" src="/images/react.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="technology-img" src="/images/nodejs.png" alt="loading image"></img> </div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="technology-img" src="/images/native.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="technology-img" src="/images/reactcore.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="technology-img" src="/images/mysql.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="technology-img" src="/images/sqlserver.jpg" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="technology-img" src="/images/mongodb.jpg" alt="loading image"></img></div>
  
 

</Carousel>;   

                

            </div>
        );
    }

} export default Techslider


