import React, { Component } from 'react';
import { } from 'reactstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Link } from "react-router-dom";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
class Customerslider1 extends React.Component {
   
    render() {
        return (
            <div>
               
              
<Carousel 
  swipeable={false}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={4000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>

  <div style={{marginLeft:"50px",marginRight:"30px"}} ><img className="customerslider" src="images/sidco.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/GNIDA.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/TEDA.png" alt="loading image"></img> </div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/updesco.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/patanjali.png" alt="loading image"></img> </div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/upsidc.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/CWC.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/tanuvas.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/apeda.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/prabha.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/sidcul.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/DDAlogo.png" alt="loading image"></img></div>
  
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/TNeGA.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/Audit.png" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/noida.jpg" alt="loading image"></img></div>
  <div style={{marginLeft:"50px",marginRight:"30px"}}><img className="customerslider" src="images/yule.gif" alt="loading image"></img></div>
  
 
 
 

</Carousel>;   

                

            </div>
        );
    }

} export default Customerslider1


