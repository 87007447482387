import React from 'react';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import Company from './components/Company';
import People from './components/People';
import Career from './components/Career';
import Contact from './components/Contact';  
import EGovernance from './components/EGovernance';
import ERP from './components/ERP'; 
import Govpublic from './components/Govpublic'; 
import OilGas from './components/OilGas';
import Customers from './components/Customers';
import Water from './components/Water';  
import Health from './components/Health'; 
import IT from './components/IT'; 
import Construction from './components/Construction';
import HR from './components/HR';
import Asset from './components/Asset';
import Procurement from './components/Procurement';
import CivilEngg from './components/CivilEngg';
import Services from './components/Services';
import Consultancy from './components/Consultancy';
import CSR from './components/CSR';
import WOCMS from './components/WOCMS';
import EPipebook from './components/EPipebook';
import ROULMS from './components/ROULMS';
import WCM from './components/WCM';
import UpStream from './components/UpStream';
import Production from './components/Production';
import Revenue from './components/Revenue';
import Gas from './components/Gas';
import LNG from './components/LNG';
import MidStream from './components/MidStream';
import Monitoring from './components/Monitoring';
import Pipeline from './components/Pipeline';

import Intellibuilding from './components/Intellibuilding';
import ScrollToTop from './components/ScrollToTop';
import ScrollUpButton from "react-scroll-up-button";
import { BrowserRouter as Switch, Router, Route, Link } from "react-router-dom";
import Footerend from './components/Footerend';




class App extends React.Component {
  render() {
    return (
      <Switch>
        <Header />
        <div style={{ marginTop: "76px" }}>
        <Route component={ScrollToTop} />
          <ScrollUpButton style={{ backgroundColor: "#2890E9", width: "40px", height: "40px" }} />
          <Route exact path="/" component={Home} />
          <Route path="/Home" component={Home} />
          <Route path="/Company" component={Company} />
          <Route path="/People" component={People} />
          <Route path="/Customers" component={Customers} />

          {/* <Route path="/Clients" component={Clients} /> */}
          <Route path="/CSR" component={CSR} />

          <Route path="/Career" component={Career} /> 
          <Route path="/Contact" component={Contact} />
          <Route path="/EGovernance" component={EGovernance} />
          <Route path="/ERP" component={ERP} />
          <Route path="/GovernmentandPublicSector" component={Govpublic} />
          <Route path="/OilGas" component={OilGas} />
          
          <Route path="/Health" component={Health} />
          <Route path="/Water" component={Water} /> 
          <Route path="/IT" component={IT} /> 
          <Route path="/Construction" component={Construction} /> 
          <Route path="/HR" component={HR} /> 
          <Route path="/assetmanagement" component={Asset} /> 
          <Route path="/ProcurementandMaterialsManagement" component={Procurement} /> 
          <Route path="/CivilEngineering" component={CivilEngg} /> 
          <Route path="/Services" component={Services} /> 
          <Route path="/WOCMS" component={WOCMS}></Route>
          <Route path="/EPipebook" component={EPipebook}></Route>
          <Route path="/ROULMS" component={ROULMS}></Route>
          <Route path="/WCM" component={WCM}></Route>
          <Route path="/UpStream" component={UpStream}></Route>
          <Route path="/Production" component={Production}></Route>
          <Route path="/Revenue" component={Revenue}></Route>
          <Route path="/Gas" component={Gas}></Route>
          <Route path="/LNG" component={LNG}></Route>
          <Route path="/MidStream" component={MidStream}></Route>
          <Route path="/Monitoring" component={Monitoring}></Route>
          <Route path="/Pipeline" component={Pipeline}></Route>
          
          <Route path="/Consultancy" component={Consultancy} /> 
          <Route path="/Intellibuilding" component={Intellibuilding}/>
          <Footer/>
          <Footerend/>
        </div>
      </Switch>
    );
  }
}

export default App;
