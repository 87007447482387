import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";



class Sidemenu extends React.Component {
    
    render() {
        return (

            <div className="sidemenu">
                <ul>
               
                                <li> <Link to="/HR" className="sidelink">Human Resource    </Link> </li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li>  <Link to="/assetmanagement" className="sidelink">Asset Management   </Link></li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li> <Link to="/ProcurementandMaterialsManagement" className="sidelink">Procurement & Materials &nbsp;&nbsp;Management  </Link> </li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li> <Link to="/CivilEngineering" className="sidelink">Civil Engineering   </Link> </li>
                                <hr className="dotted"style={{marginTop:"5px"}} ></hr>
                                <li> <Link to="/Services" className="sidelink">Services     </Link> </li>
                                <hr className="dotted"style={{marginTop:"5px"}} ></hr>
                                <li> <Link to="/Consultancy" className="sidelink">Consultancy     </Link> </li>
                                <hr className="dotted"style={{marginTop:"5px"}} ></hr>


                </ul>
                

               
            </div>
        );
    }

} export default Sidemenu


