import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";



class VedangGroup extends React.Component {

    render() {
        return (
            <div className="vgroup">
                <Container className="px-4">
                   
                    <Row className="justify-content-center" ><h2 className="vgrouph">VEDANG <span style={{ color: "#1472b1" }}>GROUP</span></h2></Row>
                    <Row>
                        <Col xs="12" sm="12" md="3" className="borderr">

                            <Row className="justify-content-center" style={{marginTop:"10px"}}><img src="/images/consulting.png"></img></Row>
                            <h4 className="vgroupsh">CONSULTING</h4>
                            <h6 className="vgroupssh">Vedang Consultancy Service Pvt Ltd (VCSPL)</h6>
                            <div className="text-justify" style={{color:"white"}}> 
                            Vedang makes innovative software for E- Governance and other various industry verticals like Oil & Gas, Water, and Healthcare & Education. We are helping the Government by providing ”Off the Shelf Ready to Use” Software platform & tools for capturing complete business processes,functions & transparent delivery of services All our solutions are available on cloud and meet the mobility through any mobile platform
                            </div>
                        </Col>

                        <Col xs="12" sm="12" md="3" className="borderr">
                            <Row className="justify-content-center" style={{marginTop:"10px"}}><img src="/images/real-estates.png"></img></Row>
                            <h4 className="vgroupsh">REAL ESTATE</h4>
                            <h6 className="vgroupssh">Vedang Real Estate Pvt Ltd (VREPL)</h6>
                            <div className="text-justify" style={{color:"white"}}>Vedang Real Estate Pvt Ltd (VREPL) aim is to build affordable, but world-class residential properties with the help of innovating technology, professionalism, ethics and customer satisfaction approach. The company has land resource in Dehradun, Haridwar and some other parts of Uttrakhand hill districts.</div>
                        </Col>

                        <Col xs="12" sm="12" md="3" className="borderr">
                            <Row className="justify-content-center"><img src="/images/hospitality.png"></img></Row>
                            <h4 className="vgroupsh">HOSPITALITY </h4>
                            <h6 className="vgroupssh">Vedang Hospitality Pvt Ltd (VHPL)</h6>
                            <div className="text-justify" style={{color:"white"}}>Vedang Hospitality (VHPL) aim is to earn customer trust by providing exceptional event management and meeting management solutions through superior customer service & creative thinking. VHPL owns 2.5 Acre farmhouse with Five Star facilities & beautiful landscaping in South Delhi which suitable for corporate houses for conducting meetings, seminars, picnic, and company get together etc.</div>
                        </Col>

                        <Col xs="12" sm="12" md="3">
                            <Row className="justify-content-center"><img src="/images/law.png"></img></Row>
                            <h4 className="vgroupsh">LAW ASSOCIATES</h4>
                            <h6 className="vgroupssh">Vedang Law Associates (VLA)</h6>
                            <div className="text-justify" style={{color:"white"}}>Vedang law associates offer a wide variety of legal services with emphasis to provide a customized legal solutions best suited to client. The expertise of Vedang Law Associates varies from corporate litigation, Taxations, Liaising, Consultation, Labor disputes, Real Estates, Consumer disputes, Matrimonial and all type of Criminal & Civil suits.</div>
                        </Col>
                    </Row>
                </Container>
                <br></br>
            </div>
        );
    }

} export default VedangGroup


