import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from "react-router-dom";

import { baseurl } from './BaseUrl';
import axios from 'axios';

import UpStreamSidemenu from './UpStreamSidemenu';

const get_pipelinecontent = baseurl + 'get_PipelineContent';

class Pipeline  extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        };
    } componentDidMount() {


        axios.post(get_pipelinecontent)
            .then(Response => {
                // alert(JSON.stringify(Response.data.data[0].home));
                this.setState({
                    data: Response.data.data[0].pipeline
                });

            })
            .catch(err => {
                console.log(err);
            })



    }
    render() {
        return (
            <div>
                <div >
                    <img src="images/oilgas.jpg" className="responsiveImg"></img>
                </div>
                <div className="about-us-bg">v</div>
                <div className="about-us-bg1" style={{ marginTop: "-13px" }}> VEDANG GAS PIPELINE   <span style={{ color: "#1472b1" }}> MANAGEMENT SYSTEM</span> </div>
                <br></br>
                <br></br>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={3}>
                            <UpStreamSidemenu />
                        </Col>
                        <Col md={9}>
                            <div className="text-justify">


                                <article>

                                    <div className="post__content" dangerouslySetInnerHTML={{ __html: this.state.data }}></div>
                                </article>

                            </div> <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

} export default  Pipeline 



