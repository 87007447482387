import React, { Component } from 'react';
import { Container,Button } from 'reactstrap';
import Slider from './Slider';
import About from './About';
import Techslider from './Techslider';
import VedangGroup from './VedangGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import axios from 'axios';
import { baseurl } from './BaseUrl';

const save_ip1 = baseurl + 'save_ip';

class Home extends React.Component {

    componentDidMount() {
   
        axios.get(save_ip1)
        .then(Response => {
          alert(Response);
       
        })
        .catch(err => {
        console.log(err);
        })
    }
    render() {
        return (
            <div>
                <Slider/>
                <About/>
                {/* <Container>  */}
             

                <div style={{ backgroundColor: "#f0f0f0" }}>
                    <Container>
                        <br></br>   <br></br>
                        <div style={{ backgroundImage: "url(/images/bluebg.png)" }}>
                            <br></br>
                            <p style={{ color: "white", textAlign: "center", padding: "30px" }}>  Want to join the VedangSoft team?
                            Want to work in a dynamic and rewarding work environment? Work with industry experts and give a boost to
                            your career with VedangSoft. Find the position that suits your aspiration.</p>
                            <div style={{ textAlign: "center" }}>
                                <Link to="/Career"> <Button className="btnnew">Click Here</Button></Link>
                            </div>

                            <br></br>
                        </div>
                        <br></br>   <br></br>
                    </Container>
                    {/* <div style={{ backgroundColor: "#E22485" }}>
                    <p style={{ color: "white", textAlign: "center", padding: "30px" }}>  Want to join the VedangSoft team?
                            Want to work in a dynamic and rewarding work environment? Work with industry experts and give a boost to
                            your career with VedangSoft. Find the position that suits your aspiration.</p>
                    </div> */}

                </div>
                <br></br> <br></br>
                <div className="about-us-bg">O</div>
                <div className="about-us-bg1" style={{marginTop:"-20px"}}>OUR <span style={{ color: "#1472b1" }}>TECHNOLOGIES</span> </div>
                <br></br>
                <Techslider/>
                {/* </Container> */}
                {/* <VedangGroup /> */}
                <br></br> <br></br>

                

            </div>
        );
    }

} export default Home


