import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Slider from './Slider';
import About from './About';
import VedangGroup from './VedangGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import axios from 'axios';
import { baseurl } from './BaseUrl';
import { Button } from 'react-bootstrap';


const visitors = baseurl + 'all_visitor';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Rows: [],
            Rows_count: 0,

        }
    }
    componentDidMount() {

        axios.get(visitors)
            .then(Response => {
                //alert(JSON.stringify(Response.data.length));
                this.setState({ Rows: Response.data })
                this.setState({ Rows_count: Response.data.length })

            })
            .catch(err => {
                console.log(err);
            })
    }
    render() {
        return (
            <div className="footer">
                <Container>
                    <br></br>
                    <Row className="justify-content-end"><h5 style={{color:"white",marginTop:"10px"}}>Visitors</h5>&nbsp;&nbsp; &nbsp;<Button className="btn btn-danger">{this.state.Rows_count} </Button></Row>
                    <Row className="justify-content-center" ><h2 className="footerh">QUICK MENU</h2></Row>

                    <Row>
                        <Col md={4}>
                            <h5 style={{ color: "white", paddingTop: "15px" }}>EXPLORE OUR SITE </h5>
                            <hr color="white"></hr>
                            <ul >
                                <li > <Link to="/" className="link">Home   </Link> </li>
                                <li> <Link to="/" className="link">About us   </Link> </li>
                                <li>  <Link to="/" className="link">Careers  </Link></li>
                                <li> <Link to="/" className="link">Contact Us   </Link> </li>


                            </ul>
                        </Col>

                        <Col md={4}>
                            <h5 style={{ color: "white", paddingTop: "15px" }} >LATEST NEWS</h5>
                            <hr color="white"></hr>
                            <br></br>
                            <h5 style={{ color: "yellow" }}> HIRING NOW</h5>
                            <p style={{ color: "white" }}>Openings For .Net
                            Professionals & SITE
                            INCHARGE ITFM</p>
                           <Link to="/Career"> <img src={'./images/click-here.png'} className="clickimg"></img></Link> 
                        </Col>
                        <Col md={4}>
                            <h5 style={{ color: "white", paddingTop: "15px" }} >CONTACT US  </h5>
                            <hr color="white"></hr>
                            <br></br>
                            <div style={{ color: "white" }}>
                                VedangSoft, <br></br>
                                2524, C-2, Vasant Kunj,<br></br>

                                New Delhi-110070 
                            
                            </div>

                        </Col>

                    </Row>

                </Container>

                <br></br> <br></br>
            </div>
        );
    }

} export default Footer


