import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import { baseurl } from './BaseUrl';

const get_peoplecontent = baseurl + 'get_PeopleContent';

class People extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        };
    } componentDidMount() {

        // axios.get(save_ip)
        // .then(Response => {
        //   alert(Response);

        // })
        // .catch(err => {
        // console.log(err);
        // })

        axios.post(get_peoplecontent)
            .then(Response => {
                // alert(JSON.stringify(Response.data.data[0].home));
                this.setState({
                    data: Response.data.data[0].people
                });

            })
            .catch(err => {
                console.log(err);
            })



    }
    render() {
        return (
            <div>
                <div >
                    <img src="/images/people.jpg" className="responsiveImg"></img>
                </div>
                <br></br>
                <br></br>
                <div className="about-us-bg">O</div>
                <div className="about-us-bg1" style={{ marginTop: "-25px" }}>OUR <span style={{ color: "#1472b1" }}>PEOPLE</span> </div>
                
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <div className="text-justify">

                                <article>

                                    <div className="post__content" dangerouslySetInnerHTML={{ __html: this.state.data }}></div>
                                </article>


                                <br></br><br></br>
                            </div> <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

} export default People


