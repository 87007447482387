import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import { baseurl } from './BaseUrl';
import Sidemenu from './Sidemenu';

const get_hrcontent = baseurl + 'get_HrContent';

class HR extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        };
    } componentDidMount() {

        axios.post(get_hrcontent)
            .then(Response => {
                // alert(JSON.stringify(Response.data.data[0].home));
                this.setState({
                    data: Response.data.data[0].hr
                });

            })
            .catch(err => {
                console.log(err);
            })



    }
    render() {
        return (
            <div>
                <div >
                    <img src="images/HR.jpg" className="responsiveImg"></img>
                </div>
                <div className="about-us-bg">h</div>
                <div className="about-us-bg1" style={{ marginTop: "-23px" }}> HUMAN <span style={{ color: "#1472b1" }}>RESOURCE</span> </div>
                
                <Container>
                    <Row className="justify-content-center">

                    <Col md={3}>
                        <Sidemenu/>
                    </Col>
                      
                        <Col md={9}>
                            <div className="text-justify">


                                <article>

                                    <div className="post__content" dangerouslySetInnerHTML={{ __html: this.state.data }}></div>
                                </article>



                            </div> <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

} export default HR


