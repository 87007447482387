import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import { baseurl } from './BaseUrl';

import Customerslider1 from './Customerslider1';
import CustomerSlider2 from './CustomerSlider2'; 
const get_customercontent = baseurl + 'get_CustomerContent';

class Customers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        };
    } componentDidMount() {

        axios.post(get_customercontent)
            .then(Response => {
                // alert(JSON.stringify(Response.data.data[0].home));
                this.setState({
                    data: Response.data.data[0].customer
                });

            })
            .catch(err => {
                console.log(err);
            })



    }
    render() {
        return (
            <div>
                <div >
                    <img src="images/clients.png" className="responsiveImg"></img>
                </div>
                <div className="about-us-bg">C</div>
                <div className="about-us-bg1" style={{ marginTop: "-26px" }}>OUR <span style={{ color: "#1472b1" }}>CUSTOMERS</span> </div>
                
                <Container>
                   {/* <Row className="justify-content-center">
                     <Col md={3}>
                        <img src="images/sidco.png" className="customerlogo"></img>
                        <img src="images/GNIDA.png" className="gnidalogo"></img>
                        <img src="images/TEDA.png" className="tedalogo"></img>
                        <img src="images/updesco.png" className="updescologo"></img>

                        <img src="images/patanjali.png" className="patanjalilogo"></img>
                        <img src="images/upsidc.png" className="upsidalogo"></img>
                        <img src="images/CWC.png" className="cwclogo"></img>
                        <img src="images/tanuvas.png" className="cwclogo"></img>

                        <img src="images/apeda.png" className="apedalogo"></img>
                        <img src="images/prabha.png" className="prabhalogo"></img>
                        <img src="images/sidcul.png" className="sidcullogo"></img><br></br>
                        <img src="images/DDAlogo.png" className="sidcullogo"></img><br></br>
                        
                        <img src="images/noida.jpg" className="noidalogo"></img><br></br>
                        <img src="images/yule.gif" className="yulelogo"></img><br></br>
                        <img src="images/TNeGA.png"  className="TNeGAlogo"></img><br></br>
                        <img src="images/Audit.png" className="Auditlogo"></img>
                      
                      
                       </Col>
                       <Col md={1}></Col>
                        <Col md={6}>
                            <div className="text-justify">

                                <article>

                                    <div className="post__content" dangerouslySetInnerHTML={{ __html: this.state.data }}></div>
                                </article>



                            </div> <br></br> <br></br><br></br>
                        </Col>
                      
                    </Row>*/}
<Row >
<Col md={3}>  <img src="images/sidco.png" className="customerslider"></img>  </Col>
<Col md={3}>  <img src="images/GNIDA.png" className="customerslider"></img> </Col>
<Col md={3}>   <img src="images/TEDA.png" className="customerslider"></img>  </Col>
<Col md={3}>   <img src="images/updesco.png" className="customerslider"></img> </Col>
                     
</Row>
<br></br><br></br>
<Row >

<Col md={3}>  <img src="images/patanjali.png" className="customerslider"></img>  </Col>
<Col md={3}>  <img src="images/upsidc.png" className="customerslider"></img> </Col>
<Col md={3}>   <img src="images/CWC.png" className="customerslider"></img>  </Col>
<Col md={3}>   <img src="images/tanuvas.png" className="customerslider"></img> </Col>
                     
</Row>
<br></br><br></br>
<Row >

<Col md={3}>  <img src="images/apeda.png" className="customerslider"></img>  </Col>
<Col md={3}>  <img src="images/prabha.png" className="customerslider"></img> </Col>
<Col md={3}>   <img src="images/sidcul.png" className="customerslider"></img>  </Col>
<Col md={3}>   <img src="images/DDAlogo.png"  className="customerslider"></img> </Col>
                     
</Row>
<br></br><br></br><br></br>
<Row >

<Col md={3}>  <img src="images/noida.jpg" className="customerslider"></img>  </Col>
<Col md={3}>  <img src="images/yule.gif" className="customerslider"></img> </Col>
<Col md={3}>   <img src="images/TNeGA.png" className="customerslider"></img>  </Col>
<Col md={3}>   <img src="images/Audit.png" className="customerslider"></img> </Col>
                     
</Row>
<br></br>

                </Container>
                {/* <Customerslider1/> */}
                <br></br>
               {/* <CustomerSlider2/>*/}
            </div>
        );
    }

} export default Customers


