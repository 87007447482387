import React, { Component } from 'react';
import { } from 'reactstrap';
import Slider from './Slider';
import About from './About';
import VedangGroup from './VedangGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare,faTwitter,faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
// import {Row,Col} from 'reactstrap';

class Footerend extends React.Component {

    render() {
        return (
            <div className="footer-copyright"> 
            
           <h6 style={{paddingTop:"10px"}}>Copyright 2021 © VedangSoft Pvt.</h6>
          {/*  <a href="https://www.instagram.com/" target ="tab" title="Follow Us"> 
            <FontAwesomeIcon className="insta" icon={faInstagram} ></FontAwesomeIcon> </a> &nbsp; 
            <a href="https://www.facebook.com" target ="tab" title="Like Us">
            <FontAwesomeIcon icon={faFacebook} className="fb" ></FontAwesomeIcon></a> &nbsp; 
            <a href=" https://www.twitter.com/" target ="tab" title="Follow Us">
            <FontAwesomeIcon icon= {faTwitter} className="tw"></FontAwesomeIcon></a>&nbsp;&nbsp;
           <a href="https://www.linkedin.com/" target ="tab" title="Follow Us"> 
          <FontAwesomeIcon className="linkdin" icon={faLinkedin} ></FontAwesomeIcon> </a> */}
        </div>
        );
    }

} export default Footerend


