import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";



class Sidemenunav extends React.Component {
    
    render() {
        return (

            <div className="sidemenu">
                <ul>
               
                                <li> <Link to="/EGovernance" className="sidelink">E- Governance     </Link> </li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li>  <Link to="/ERP" className="sidelink">ERP    </Link></li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li> <Link to="/GovernmentandPublicSector" className="sidelink">Government & Public &nbsp;&nbsp;Sector  </Link> </li>
                                <hr className="dotted" style={{marginTop:"5px"}}></hr>
                                <li> <Link to="/OilandGas" className="sidelink">Oil & Gas    </Link> </li>
                                <hr className="dotted"style={{marginTop:"5px"}} ></hr>
                                <li> <Link to="/Water" className="sidelink">Water     </Link> </li>
                                <hr className="dotted"style={{marginTop:"5px"}} ></hr>
                                <li> <Link to="/Health" className="sidelink">Health     </Link> </li>
                                <hr className="dotted"style={{marginTop:"5px"}} ></hr>
                                <li> <Link to="/IT" className="sidelink">Information Technology     </Link> </li>
                                <hr className="dotted"style={{marginTop:"5px"}} ></hr>
                                <li> <Link to="/Construction" className="sidelink">Construction     </Link> </li>
                                <hr className="dotted"style={{marginTop:"5px"}} ></hr>


                </ul>
                

               
            </div>
        );
    }

} export default Sidemenunav


