import React, { Component } from 'react';
import { Container,Row,Col } from 'reactstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUserCircle, faImages, faAddressCard, faGlobe, faMagic, faNetworkWired, faKey, faArrowAltCircleLeft, faHistory, faHandHoldingUsd, faFeatherAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from "react-router-dom";
import { baseurl } from './BaseUrl';
import axios from 'axios';


const get_companycontent = baseurl + 'get_CompanyContent';

class Company extends React.Component {
    constructor(props) {
        super(props);

this.state = {
            data: ''
        };
       
    }
	
	componentDidMount() {
   
        axios.post(get_companycontent)
        .then(Response => {
        // alert(JSON.stringify(Response.data.data[0].funding));
        this.setState( {
        data:Response.data.data[0].company
        } );
        
        })
        .catch(err => {
        console.log(err);
        })
        
        
            }
    render() {
        return (
            <div>
               <div >
                    <img src="/images/company.jpg" className="responsiveImg"></img>
                </div>
                <br></br>
                <br></br>

                <div className="about-us-bg">C</div>
                <div className="about-us-bg1" style={{marginTop:"-25px"}}>OUR <span style={{ color: "#1472b1" }}>COMPANY</span> </div>
              
                <Container>
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <div className="text-justify">

                            <div className="post__content" dangerouslySetInnerHTML={{__html: this.state.data}}></div>

                             </div> <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>  
            </div>
        );
    }

} export default Company


