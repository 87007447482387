import React, { Component } from 'react';
import {
  Carousel, 
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';


const items = [

  // {
  //   id:1 ,
   
  //   src: "images/5.jpg",
  //   altText: '',
  //   caption: '',
  // },
  {
    id:2 ,
    src: 'https://i.postimg.cc/Z5tpMcdc/1.jpg',
   
    altText: '',
    caption: '',
  },
  {
    id:3 ,
    src: 'https://i.postimg.cc/NM5xc0f7/2.jpg',
    
    altText: '',
    caption: '',
  },
  {
    id:4 ,
   
    src: 'https://i.postimg.cc/nhFBxL3j/3.jpg',
    altText: '',
    caption: '',
  },
  {
    id:5 ,
   
    src: "https://i.postimg.cc/7Lp55PnN/4.jpg",
    altText: '',
    caption: '',
  }
  
];

class Slider extends Component {
  constructor() {
    super();
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (

        <CarouselItem
          key={item.id}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <img className="imgctr" src={item.src} alt={item.altText} className="slider"  />

          <CarouselCaption className="text-danger" captionText={item.caption} captionHeader={item.caption} />
        </CarouselItem>
     
      );
    });

    return (
      <div>
        {/* <style>
          {
            `.custom-tag {
                max-width: 100%;
                height: 500px;
                background: black;
              }`
          }
        </style> */}
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>

      </div>

    );
  }
}
export default Slider;