import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import { Link } from "react-router-dom";
import { baseurl } from './BaseUrl';

const get_egovcontent = baseurl + 'get_EgovContent';

class EGovernance extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        };
    } componentDidMount() {


        axios.post(get_egovcontent)
            .then(Response => {
                // alert(JSON.stringify(Response.data.data[0].home));
                this.setState({
                    data: Response.data.data[0].egov
                });

            })
            .catch(err => {
                console.log(err);
            })



    }
    render() {
        return (
            <div>
                <div >
                    <img src="images/e-governance.jpg" className="responsiveImg"></img>
                </div>
                <div className="about-us-bg">G</div>
                <div className="about-us-bg1" style={{ marginTop: "-25px" }}>E- <span style={{ color: "#1472b1" }}>GOVERNANCE</span> </div>
               
                <Container>
                    <Row className="justify-content-center">
                        <Col md={9}>
                            <div className="text-justify">



                                <article>

                                    <div className="post__content" dangerouslySetInnerHTML={{ __html: this.state.data }}></div>
                                </article>

{/* 

                                Vedang “E-Governance suite” has the following Modules for efficient and transparent functioning of all customers/public centric departments.
                                <br></br>
                                <br></br>
                                FILE TRACKING & MANAGEMENT <br></br>  <br></br>
                                RTI MONITORING & MANAGEMENT <br></br>  <br></br>
                                ASSEMBLY QUESTIONS MONITORING & MANAGEMENT <br></br>  <br></br>
                                CM PETITION MONITORING & MANAGEMENT <br></br>  <br></br>
                                LITIGATION MONITORING & MANAGEMENT <br></br>  <br></br>
                                GRIEVANCE MONITORING & MANAGEMENT <br></br>  <br></br>
                                HRMS360 <br></br>  <br></br>
                                PROCUREMENT & ASSET MANAGEMENT <br></br>  <br></br>
                                FINANCE & ACCOUNTING SYSTEM <br></br>  <br></br>
                                WORK ORDER CONSTRUCTION MANAGEMENT SYSTEM <br></br>  <br></br> */}


                            </div> <br></br> <br></br><br></br>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }

} export default EGovernance


